<template>
  <div class="profile__wrapper">
    <div
      v-for="group in getModel.group"
      :key="group.name"
      class="profile__block"
    >
      <h4 class="title-h4 profile__title">
        {{ $t(`profile.${group.name}`) }}
      </h4>
      <div class="profile__grid">
        <div
          v-for="item in group.fields"
          :key="item.name"
          :class="`${item.column_type}`"
        >
          <text-field
            :id="item.name"
            v-model="getCurUserInfo[item.name]"
            :placeholder="$t(`profile.${item.name}`)"
            is-disabled
          />
        </div>
      </div>
    </div>
    <h4 class="title-h4 eu-key__title">
      {{ $t('profile.electronic_key') }}
    </h4>
    <EuKey :isUploadKey="!isUserHasEuKey" class="mb-16" />
    <Button class="print__btn" icon-name="print" @click.native="printWindow">
      {{ $t('buttons.print') }}
    </Button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import profile from '../../models/dart/profile';
import EuKey from '@/elements/euKey/EuKey.vue';

export default {
  components: { EuKey },

  data() {
    return {
      user: {},
    };
  },

  created() {
    this.$store.dispatch('getAuthUserData');
    this.$store.commit('SET_CURRENT_FORM_TYPE', 'profile');
  },

  computed: {
    ...mapGetters(['getAuthUserData', 'getCurrentFormType', 'isUserHasEuKey']),

    getModel() {
      return profile;
    },

    getCurUserInfo() {
      let obj = {};
      if (!!this.getAuthUserData) {
        for (let key in this.getAuthUserData) {
          let val = !!this.getAuthUserData[key]
            ? this.getAuthUserData[key]
            : '-';
          obj[key] = val;
        }
      }
      return obj;
    },
  },

  methods: {
    printWindow() {
      window.print();
    },
  },
};
</script>
